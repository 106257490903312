"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Builder = exports.Kind = void 0;
var schemas_1 = require("./schemas");
var _1 = require("./");
var Kind;
(function (Kind) {
    Kind[Kind["Entity"] = 0] = "Entity";
    Kind[Kind["Attribute"] = 1] = "Attribute";
    Kind[Kind["Attributes"] = 2] = "Attributes";
})(Kind = exports.Kind || (exports.Kind = {}));
var isDrillable = function (node) { return node.kind === Kind.Entity; };
// This method may only be called for nodes of kind Entity
var prependAttributes = function (node, attributes, createNew) {
    if (!node.children.length) {
        node.children.push(Builder.fromAttributesWithCallback(attributes, node.uri, createNew));
        return;
    }
    // Expect the zeroth element to be of type Attributes.
    // Otherwise assume node has no attributes.
    if (node.children[0].kind !== Kind.Attributes) {
        node.children = __spreadArrays([
            Builder.fromAttributesWithCallback(attributes, node.uri, createNew)
        ], node.children);
        return;
    }
    var toPrepend = attributes.map(function (a) {
        return Builder.fromAttributeWithCallback(a, _1.muri.pushAttributeFolder(node.uri), createNew);
    });
    node.children[0].children = __spreadArrays(toPrepend, node.children[0].children);
};
// A builder class to create nodes using an Entity Relation model.
var Builder = /** @class */ (function () {
    function Builder(model, createNew) {
        var _this = this;
        this.fromAttributes = function (attributes, prefix) {
            return Builder.fromAttributesWithCallback(attributes, prefix, _this.createNew);
        };
        // Create a node's children
        // node argument is mutated.
        this.drillNode = function (node) {
            if (!isDrillable(node))
                return;
            if (node.children.length)
                return;
            var entity = node.reference;
            var attributesNode = _this.fromAttributes(entity.getCustomAttributes(), node.uri);
            var nested = _this.model
                .getRelationsOfEntity(entity.getUrn())
                .map(function (relationUrn) { return _this.model.findRelationByUrn(relationUrn); })
                .map(function (relation) {
                var other = _this.model.getRelatedEntity(entity.getUrn(), relation.getUrn());
                return _this.fromEntity(other, node.uri, relation);
            });
            node.children = __spreadArrays([attributesNode], nested);
            return;
        };
        // Remove a node's children, if any.
        // node argument is mutated.
        this.sealNode = function (node) {
            if (!isDrillable(node))
                return;
            if (!node.children.length)
                return;
            node.children = [];
            return;
        };
        this.fromEntity = function (entity, prefix, relationWithParent) {
            return Builder.fromEntityStatic(entity, prefix, _this.createNew, relationWithParent);
        };
        this.rootFromEntity = function (urn) {
            var entity = _this.model.findEntityByUrn(urn);
            var node = _this.fromEntity(entity, "");
            _this.drillNode(node);
            return node;
        };
        this.model = model;
        this.createNew = createNew;
    }
    // Static methods
    Builder.fromEntityStatic = function (entity, prefix, createNew, relationWithParent) {
        var meta = {
            uri: _1.muri.join(prefix, entity.getUrn()),
            reference: entity,
            kind: Kind.Entity,
            isRoot: prefix.length === 0,
            relationWithParent: relationWithParent,
        };
        return __assign(__assign({}, meta), { inner: createNew(meta), children: [] });
    };
    Builder.entityWithAttributes = function (entity, createNew, attributes) {
        var root = Builder.fromEntityStatic(entity, "", createNew);
        var attributesNode = Builder.fromAttributesWithCallback(attributes || entity.getCustomAttributes(), root.uri, createNew);
        root.children = [attributesNode];
        return root;
    };
    // This method mutates the node in place.
    Builder.insertDefaultAttributes = function (node, createNew) {
        var traverse = function (n) {
            if (n.kind === Kind.Entity) {
                prependAttributes(n, schemas_1.CommonAttributes, createNew);
            }
        };
        Builder.traversePreOrder(node, traverse);
    };
    Builder.fromAttributeWithCallback = function (attribute, prefix, createNew) {
        var meta = {
            uri: _1.muri.join(prefix, attribute.id),
            reference: attribute,
            kind: Kind.Attribute,
        };
        return __assign(__assign({}, meta), { inner: createNew(meta), children: [] });
    };
    Builder.fromAttributesWithCallback = function (attributes, prefix, createNew) {
        var meta = {
            uri: _1.muri.pushAttributeFolder(prefix),
            reference: attributes,
            kind: Kind.Attributes,
        };
        return __assign(__assign({}, meta), { inner: createNew(meta), children: attributes.map(function (a) {
                return Builder.fromAttributeWithCallback(a, meta.uri, createNew);
            }) });
    };
    Builder.intoInner = function (node, setChildren) {
        var inner = node.inner;
        if (node.children.length)
            setChildren(inner, node.children.map(function (n) { return Builder.intoInner(n, setChildren); }));
        return inner;
    };
    Builder.clone = function (node, innerClone) {
        var copy = __assign(__assign({}, node), { inner: innerClone(node.inner) });
        if (node.children)
            copy.children = node.children.map(function (n) {
                return Builder.clone(n, innerClone);
            });
        return copy;
    };
    // Clone the node while converting the inner type.
    // filter callback is not applied to the root node.
    Builder.cloneInto = function (node, into, filter) {
        var children = node.children.length
            ? node.children
                .filter(function (n) { return (filter ? filter(n) : true); })
                .map(function (n) { return Builder.cloneInto(n, into, filter); })
            : [];
        return __assign(__assign({}, node), { inner: into(node), children: children });
    };
    // A hollow clone is a Node with a null object as generic T.
    Builder.hollowClone = function (node, filter) { return Builder.cloneInto(node, function (t) { return null; }, filter); };
    Builder.findByURI = function (node, uri) {
        if (node.uri === uri)
            return node;
        var match = node.children.find(function (n) { return _1.muri.isAncestorOf(n.uri, uri); });
        return match && Builder.findByURI(match, uri);
    };
    // Post order traversal
    Builder.traversePostOrder = function (node, traverse) {
        if (node.children.length > 0)
            node.children.forEach(function (n) {
                return Builder.traversePostOrder(n, traverse);
            });
        traverse(node);
    };
    // Pre order traversal
    Builder.traversePreOrder = function (node, traverse) {
        traverse(node);
        if (node.children.length > 0)
            node.children.forEach(function (n) { return Builder.traversePreOrder(n, traverse); });
    };
    // Sanitize by removing entities with no children
    Builder.sanitize = function (root) {
        Builder.traversePreOrder(root, function (node) {
            for (var i = node.children.length - 1; i >= 0; i--) {
                if (node.children[i].kind === Kind.Entity) {
                    if (node.children[i].children.length === 0)
                        node.children.splice(i, 1);
                }
            }
        });
    };
    return Builder;
}());
exports.Builder = Builder;
