"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Model = void 0;
// A model containing all entities and relations
var Model = /** @class */ (function () {
    function Model(inner) {
        var _this = this;
        this.findEntityByUrn = function (urn) {
            return _this.entities.get(urn);
        };
        this.findRelationByUrn = function (urn) {
            return _this.relations.get(urn);
        };
        this.getEntities = function () { return Array.from(_this.entities.values()); };
        this.getRelations = function () { return Array.from(_this.relations.values()); };
        this.getBase = function () { return _this.base; };
        this.getTenant = function () { return _this.base.tenant; };
        this.getRelatedEntity = function (entityUrn, relationUrn) {
            var relation = _this.findRelationByUrn(relationUrn);
            if (!relation)
                return undefined;
            if (relation.getOrigin() === entityUrn)
                return _this.findEntityByUrn(relation.getDestination());
            if (relation.getDestination() === entityUrn)
                return _this.findEntityByUrn(relation.getOrigin());
            return undefined;
        };
        this.getRelationsOfEntity = function (entityUrn) {
            return _this.relationsByEntity.get(entityUrn) || [];
        };
        this.getRelationsBetween = function (entityUrnA, entityUrnB) {
            return _this.getRelationsOfEntity(entityUrnA)
                .filter(function (relationUrn) { var _a; return ((_a = _this.findRelationByUrn(relationUrn)) === null || _a === void 0 ? void 0 : _a.getSide(entityUrnB)) !== undefined; });
        };
        this.entities = new Map(inner.entities.map(function (o) { return [o.getUrn(), o]; }));
        this.relations = new Map(inner.relations.map(function (o) { return [o.getUrn(), o]; }));
        // Verify that all relations have existing entities
        this.relations.forEach(function (relation) {
            if (!_this.entities.get(relation.getOrigin()) ||
                !_this.entities.get(relation.getDestination()))
                throw new Error("entity(ies) of relation " + relation.getId() + " not found");
        });
        this.relationsByEntity = new Map(inner.entities.map(function (entity) { return [
            entity.getUrn(),
            inner.relations
                .filter(function (relation) {
                return relation.getOrigin() === entity.getUrn() ||
                    relation.getDestination() === entity.getUrn();
            })
                .map(function (relation) { return relation.getUrn(); }),
        ]; }));
        this.base = inner;
    }
    return Model;
}());
exports.Model = Model;
