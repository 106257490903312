"use strict";
// We use custom URIs to address the selected attributes and entities.
// The following format denotes the key of an entity related to the first one:
// `<entity_urn>/<other_entity_urn>`
// Deeper nesting can be used:
// `<entity_urn>/<other_entity_urn>/<yet_another_entity>`
// The dot '.' is used to identify the attributes:
// `entity-a/entity-b/./created_at`
// which is the key for the attribute `created_at` from the entity `entity-b`
// as a relation of `entity-a`.
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAncestors = exports.popRoot = exports.deepestUrn = exports.notFolder = exports.getParent = exports.isAncestorOf = exports.isDescendantOf = exports.isDrillable = exports.pushAttributeFolder = exports.pushAttribute = exports.split = exports.join = exports.depth = exports.basename = exports.isAttributeFolder = exports.isAttribute = void 0;
var ATTR_DELIM = ".";
var URN_SEP = "/";
exports.isAttribute = function (uri) {
    return uri.lastIndexOf(ATTR_DELIM) === uri.lastIndexOf(URN_SEP) - 1;
};
exports.isAttributeFolder = function (uri) {
    return uri.endsWith(ATTR_DELIM);
};
exports.basename = function (uri) {
    return uri.slice(uri.lastIndexOf(URN_SEP) + 1);
};
exports.depth = function (uri) { return uri.split(URN_SEP).length; };
exports.join = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args[0].length ? args.join(URN_SEP) : args.slice(1).join(URN_SEP);
};
exports.split = function (uri) { return uri.split(URN_SEP); };
exports.pushAttribute = function (uri, attribute) {
    return exports.join(uri, ATTR_DELIM, attribute);
};
exports.pushAttributeFolder = function (uri) {
    return exports.join(uri, ATTR_DELIM);
};
// A URI can be drilled if it is not a root node
exports.isDrillable = function (uri) {
    return !exports.isAttribute(uri) && !exports.isAttributeFolder(uri) && exports.depth(uri) > 1;
};
exports.isDescendantOf = function (uri, ancestor) {
    return uri.startsWith(ancestor);
};
exports.isAncestorOf = function (uri, descendant) {
    return exports.isDescendantOf(descendant, uri);
};
exports.getParent = function (uri) {
    var lastIndex = uri.lastIndexOf(URN_SEP);
    return lastIndex < 0 ? "" : uri.slice(0, lastIndex);
};
exports.notFolder = function (uri) {
    return exports.isAttribute(uri)
        ? exports.getParent(exports.getParent(uri))
        : exports.isAttributeFolder(uri)
            ? exports.getParent(uri)
            : uri;
};
// The deepest URN is the right most URN and should be an entity or relation.
exports.deepestUrn = function (uri) { return exports.basename(exports.notFolder(uri)); };
// Remove the root folder of the path
exports.popRoot = function (uri) {
    var index = uri.indexOf(URN_SEP);
    return index < 0 ? "" : uri.slice(index + 1);
};
exports.getAncestors = function (uri) {
    var ancestors = [];
    var parent = uri;
    while (exports.depth(parent) > 1) {
        parent = exports.getParent(parent);
        ancestors.push(parent);
    }
    return ancestors;
};
