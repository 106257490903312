"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutationResponse = exports.QueryResponse = exports.Resolver = exports.objectIntoNamedFields = exports.Methods = void 0;
var schemas_1 = require("./schemas");
var _1 = require("./");
var tree_1 = require("./tree");
var naming_1 = require("./naming");
var Methods;
(function (Methods) {
    // Queries
    Methods[Methods["TypeName"] = 0] = "TypeName";
    Methods[Methods["QueryOne"] = 1] = "QueryOne";
    Methods[Methods["QueryMany"] = 2] = "QueryMany";
    // Mutations
    Methods[Methods["CreateOne"] = 3] = "CreateOne";
    Methods[Methods["CreateMany"] = 4] = "CreateMany";
    Methods[Methods["UpdateOne"] = 5] = "UpdateOne";
    Methods[Methods["UpdateMany"] = 6] = "UpdateMany";
    Methods[Methods["DeleteOne"] = 7] = "DeleteOne";
    Methods[Methods["AddTo"] = 8] = "AddTo";
    Methods[Methods["RemoveFrom"] = 9] = "RemoveFrom";
})(Methods = exports.Methods || (exports.Methods = {}));
;
/**
 * Wraps braces around stringable tokens.
 */
var Braced = /** @class */ (function () {
    function Braced(inner) {
        var _this = this;
        this.toString = function () { return "{ " + _this.inner.toString() + " }"; };
        this.inner = inner;
    }
    return Braced;
}());
/// A list of comma separated fields, each field can be a FieldSet, such as:
/// `label, vendor, categories { id, name }, stock`
var Fields = /** @class */ (function () {
    function Fields(inner) {
        var _this = this;
        this.toString = function () { return _this.inner.map(function (o) { return o.toString(); }).join(", "); };
        this.inner = inner;
    }
    return Fields;
}());
/// An ident (name) followed by a list of fields, such as:
/// `parts { label, vendor }`
var FieldSet = /** @class */ (function () {
    function FieldSet(name, fields) {
        var _this = this;
        this.toString = function () { return _this.name + " " + _this.inBraces.toString(); };
        this.name = name;
        this.inBraces = new Braced(fields);
    }
    return FieldSet;
}());
/// A named field typically used in function arguments, such as:
/// `categories: anystring`
var NamedField = /** @class */ (function () {
    function NamedField(name, field) {
        var _this = this;
        this.toString = function () { return _this.name + ": " + _this.field.toString(); };
        this.name = name;
        this.field = field;
    }
    return NamedField;
}());
/// A function call in the form:
/// `call( fieldA: valueA, fieldB: valueB )`
var Call = /** @class */ (function () {
    function Call(name, args) {
        var _this = this;
        this.toString = function () { return _this.name + "( " + _this.args + " )"; };
        this.name = name;
        this.args = typeof args === "string" ? args : args.join(", ");
    }
    return Call;
}());
// Template call with offset, limit, where and include clause
var baseCall = function (name, offset, limit, where, include) {
    var args = [
        new NamedField("offset", offset),
        new NamedField("limit", limit),
    ];
    if (where !== undefined && where.length > 0) {
        args.push(new NamedField("where", where));
    }
    if (include !== undefined && include.length > 0) {
        args.push(new NamedField("include", include));
    }
    return new Call(name, args);
};
var basicQuery = function (prefix, fields) { return "{\n    " + prefix + " { " + fields.toString() + " }\n}"; };
var parseWhere = function (arg, options) {
    switch (typeof arg) {
        case "string":
            return arg;
        case "function":
            var clause = arg(options);
            if (typeof clause === "string")
                return clause;
            return exports.objectIntoNamedFields(clause);
        case "object":
            return exports.objectIntoNamedFields(arg);
    }
};
var parseInclude = function (raw, options) {
    var fields = new Fields(Object.keys(raw).map(function (key) {
        var whereContents = new Braced(parseWhere(raw[key].where, options));
        var whereClause = new NamedField("where", whereContents);
        var clauses = [whereClause];
        var nested = raw[key].include;
        if (nested)
            clauses.push(new NamedField("include", parseInclude(nested, options)));
        var innerFields = new Fields(clauses.map(function (s) { return s.toString(); }));
        var entry = new NamedField(key, new Braced(innerFields));
        return entry.toString();
    }));
    return (new Braced(fields)).toString();
};
var intoIncludeClause = function (node, options) {
    var raw = node.inner.rawInclude;
    if (!raw || Object.keys(raw).length === 0)
        return undefined;
    return parseInclude(raw, options);
};
var textLikeClause = function (fieldName, text) {
    return fieldName + ": { like: \"%" + text + "%\" }";
};
var intoWhereClause = function (node, options) {
    var whereText = whereTextSearch(node, options.textSearch || "");
    var custom = whereCustom(node, options);
    if (whereText && !custom)
        return "{ or: { " + whereText + " } }";
    if (whereText && custom)
        return "{ and: { or: { " + whereText + " }, " + custom + " } }";
    if (custom)
        return "{ " + custom + " }";
    return undefined;
};
var whereCustom = function (node, options) {
    return node.inner.customWhere && parseWhere(node.inner.customWhere, options);
};
var whereTextSearch = function (node, text) {
    if (node.kind !== tree_1.Kind.Entity) {
        throw new Error("where clause can only use EntitySpec");
    }
    // If node does not have attributes as a child,
    // then no where clause is created.
    if (!node.children.length)
        return undefined;
    // text searchable attributes
    var attributes = node.children[0].children.filter(function (n) { return n.inner.byText; });
    if (!attributes.length)
        return undefined;
    return attributes
        .map(function (a) { return a.reference.id; })
        .map(function (id) { return textLikeClause(id, text); })
        .join(", ");
};
var intoCall = function (node, options) {
    return baseCall(node.reference.getPlural(), 0, options.limit || 5, intoWhereClause(node, options), intoIncludeClause(node, options));
};
// Assumes the node kind is Entity.
// Entity may be queried with a call, such as:
// parts (limit: 5, offset: 0) { ... }
// or as a FieldSet, such as
// parts { ... }
// This depends on the relation with parent entity.
var entityIntoFields = function (node, options) {
    var entity = node.reference;
    var relation = node.relationWithParent;
    var withQuery = false;
    var withSingular = false;
    if (relation) {
        var side = relation.getSide(entity.getUrn());
        withQuery = side === schemas_1.RelationSide.ManyToOne;
        withSingular = side === schemas_1.RelationSide.OneToMany;
    }
    var name = withQuery
        ? intoCall(node, options).toString()
        : withSingular
            ? entity.getSingular()
            : entity.getPlural();
    var fields = node.children.map(function (n) { return intoFields(n, options).toString(); });
    var fieldSet = new FieldSet(name, new Fields(fields));
    return new Fields([fieldSet]);
};
var intoFields = function (node, options) {
    switch (node.kind) {
        case tree_1.Kind.Attributes:
            return new Fields(node.children.map(function (n) { return n.reference.id; }));
        case tree_1.Kind.Entity:
            return entityIntoFields(node, options);
        case tree_1.Kind.Attribute:
            throw new Error("must not call with attribute");
    }
};
// Converts an arbitrary object into a list of named fields.
// For instance:
// { "foo": "bar", "qux": 2 }
// becomes:
// { foo: "bar", qux: 2 }
exports.objectIntoNamedFields = function (object) { return Object.keys(object).map(function (field) {
    var value = String(object[field]);
    switch (typeof object[field]) {
        case "string":
            value = JSON.stringify(value);
            break;
        case "object":
            if (object[field] === null || object[field] === undefined) {
                value = "null";
                break;
            }
            if (Array.isArray(object[field])) {
                var array = object[field]
                    .map(function (obj) {
                    return (typeof obj === "object") ?
                        "{ " + exports.objectIntoNamedFields(obj) + " }" :
                        String(obj);
                }).join(", ");
                value = "[ " + array + " ]";
            }
            else {
                value = "{ " + exports.objectIntoNamedFields(object[field]) + " }";
            }
            break;
    }
    return field + ": " + value;
}).join(", "); };
var Resolver = /** @class */ (function () {
    function Resolver(model, queryUrl, token) {
        var _this = this;
        this.setHeaders = function (headers) {
            headers.set("Authorization", "Bearer " + _this.token);
            headers.set("Content-Type", "application/graphql");
        };
        this.bareRequest = function (body, url) {
            var headers = new Headers();
            _this.setHeaders(headers);
            return new Request(url, {
                method: "POST",
                body: body,
                headers: headers,
            });
        };
        this.bareQuery = function (body) { return _this.bareRequest(body, _this.queryUrl); };
        // Build a query from the options
        this.createQuery = function (options) {
            if (!options.params)
                return "";
            var root = options.params;
            var rootEntity = root.reference;
            var call = baseCall(rootEntity.getPlural(), 0, options.limit || 5, intoWhereClause(root, options), intoIncludeClause(root, options));
            var fields = root.children
                .map(function (n) { return intoFields(n, options).toString(); })
                .join(", ");
            return basicQuery(call.toString(), new Fields([fields]));
        };
        // Root node in QueryParams must be Kind.Entity
        this.requestDataObjects = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var query, result, asJson, dataObjects, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this.createQuery(options);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch(this.bareQuery(query))];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, result.json()];
                    case 3:
                        asJson = _a.sent();
                        dataObjects = asJson.data;
                        if (!dataObjects) {
                            throw JSON.stringify(asJson.errors);
                        }
                        // Cannot validate here since the objects attributes
                        // may not all be there.
                        /* entity.validate(dataObjects); */
                        return [2 /*return*/, new QueryResponse(options, this.model, dataObjects, asJson.errors && new Error(JSON.stringify(asJson.errors)))];
                    case 4:
                        error_1 = _a.sent();
                        return [2 /*return*/, new QueryResponse(options, this.model, undefined, new Error(error_1))];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.mutateObject = function (verb, args, outputFields) { return __awaiter(_this, void 0, void 0, function () {
            var call, withSubField, fields, query, result, asJson, dataObjects, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        call = new Call(verb, args);
                        withSubField = new FieldSet(call.toString(), outputFields);
                        fields = new Fields([withSubField.toString()]);
                        query = new FieldSet("mutation", fields).toString();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch(this.bareQuery(query))];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, result.json()];
                    case 3:
                        asJson = _a.sent();
                        dataObjects = asJson.data;
                        if (!dataObjects) {
                            throw JSON.stringify(asJson.errors);
                        }
                        return [2 /*return*/, new MutationResponse(verb, dataObjects, asJson.errors && new Error(JSON.stringify(asJson.errors)))];
                    case 4:
                        error_2 = _a.sent();
                        return [2 /*return*/, new MutationResponse(verb, undefined, new Error(error_2))];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.createObject = function (entity, payload) { return __awaiter(_this, void 0, void 0, function () {
            var namedFields, args;
            return __generator(this, function (_a) {
                namedFields = exports.objectIntoNamedFields(payload);
                args = [new NamedField("input", "[{ " + namedFields + " }]")];
                return [2 /*return*/, this.mutateObject("bulkCreate" + naming_1.graphqlEntityTypeName(entity), args, new Fields([schemas_1.ID_FIELD]))];
            });
        }); };
        this.updateObject = function (entity, payload) { return __awaiter(_this, void 0, void 0, function () {
            var namedFields, args;
            return __generator(this, function (_a) {
                namedFields = exports.objectIntoNamedFields(payload);
                args = [new NamedField("input", "[{ " + namedFields + " }]")];
                return [2 /*return*/, this.mutateObject("bulkUpdate" + naming_1.graphqlEntityTypeName(entity), args, new Fields([schemas_1.ID_FIELD]))];
            });
        }); };
        this.deleteObject = function (entity, payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.mutateObject("delete" + naming_1.graphqlEntityTypeName(entity), [new NamedField("id", payload.id.toString())], new Fields(["success"]))];
            });
        }); };
        this.model = model;
        this.token = token;
        this.queryUrl = queryUrl;
    }
    return Resolver;
}());
exports.Resolver = Resolver;
var uriToJsonKeys = function (node, uri) {
    if (uri.length === 0)
        return [];
    var parts = _1.muri.split(uri);
    var keys = [];
    var cursor;
    parts.forEach(function (part, i) {
        cursor = !i
            ? node
            : tree_1.Builder.findByURI(cursor, _1.muri.join(cursor.uri, part));
        switch (cursor.kind) {
            case tree_1.Kind.Attribute:
                keys.push(part);
                break;
            case tree_1.Kind.Attributes:
                break;
            case tree_1.Kind.Entity:
                var entity = cursor.reference;
                var relation = cursor.relationWithParent;
                if (!relation) {
                    keys.push(entity.getPlural());
                    break;
                }
                keys.push(relation.getSide(entity.getUrn()) === schemas_1.RelationSide.OneToMany
                    ? entity.getSingular()
                    : entity.getPlural());
                break;
        }
    });
    return keys;
};
var QueryResponse = /** @class */ (function () {
    function QueryResponse(options, model, data, error) {
        var _this = this;
        this.getParams = function () { return _this.options.params; };
        this.getError = function () { return _this.error; };
        this.getData = function () { return _this.data; };
        this.indexObjectByKeys = function (object, keys) {
            var result = object;
            var i;
            var _loop_1 = function () {
                result = result[keys[i]];
                if (!result)
                    return { value: result };
                if (Array.isArray(result)) {
                    var remaining_1 = keys.slice(i + 1);
                    return { value: result
                            .map(function (item) {
                            var _a;
                            return (_a = _this.indexObjectByKeys(item, remaining_1)) === null || _a === void 0 ? void 0 : _a.toString();
                        })
                            .join(", ") };
                }
            };
            for (i = 0; i < keys.length; i++) {
                var state_1 = _loop_1();
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            return result;
        };
        this.indexByURIs = function (uris) {
            if (!_this.data || !uris.length)
                return {};
            var data = _this.data;
            var root = _1.muri.split(uris[0])[0];
            var entity = _this.model.findEntityByUrn(root);
            var params = _this.getParams();
            var objectList = data[entity.getPlural()];
            return objectList.reduce(function (indexed, object) {
                indexed[object[schemas_1.ID_FIELD]] = uris.reduce(function (byURI, uri) {
                    var keys = uriToJsonKeys(params, uri);
                    byURI[uri] = _this.indexObjectByKeys(object, keys.slice(1));
                    return byURI;
                }, {});
                return indexed;
            }, {});
        };
        this.data = data;
        this.error = error;
        this.options = options;
        this.model = model;
    }
    return QueryResponse;
}());
exports.QueryResponse = QueryResponse;
;
var MutationResponse = /** @class */ (function () {
    function MutationResponse(verb, data, error) {
        var _this = this;
        this.getError = function () { return _this.error; };
        this.getData = function () { return _this.data; };
        this.verb = verb;
        this.data = data;
        this.error = error;
    }
    return MutationResponse;
}());
exports.MutationResponse = MutationResponse;
;
